import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Amstel Gold Race",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-09 17:26:29",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22591: {
        teamId: "22591",
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        teamNationCode: "ITA",
      },
      23137: {
        teamId: "23137",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      23398: {
        teamId: "23398",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
    },
    riders: {
      39186: {
        id: 39186,
        startno: 214,
        firstName: "Julian",
        lastName: "Mertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-10-06",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      37717: {
        id: 37717,
        startno: 145,
        firstName: "Dorian",
        lastName: "Godon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      111801: {
        id: 111801,
        startno: 95,
        firstName: "Marco",
        lastName: "Brenner",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-08-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 5,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      20822: {
        id: 20822,
        startno: 221,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      34624: {
        id: 34624,
        startno: 157,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      38938: {
        id: 38938,
        startno: 206,
        firstName: "Sandy",
        lastName: "Dujardin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-05-29",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      66439: {
        id: 66439,
        startno: 247,
        firstName: "Filippo",
        lastName: "Zana",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-18",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      312: {
        id: 312,
        startno: 66,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      27331: {
        id: 27331,
        startno: 81,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 94,
        firstName: "S\u00c3\u00b8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      49901: {
        id: 49901,
        startno: 232,
        firstName: "Victor",
        lastName: "Koretzky",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-08-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      65240: {
        id: 65240,
        startno: 12,
        firstName: "Andrea",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-23",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 22,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      45354: {
        id: 45354,
        startno: 211,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      46167: {
        id: 46167,
        startno: 104,
        firstName: "I\u00c3\u00b1igo",
        lastName: "Elosegui",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      65025: {
        id: 65025,
        startno: 21,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16660: {
        id: 16660,
        startno: 174,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 76,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 166,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      64650: {
        id: 64650,
        startno: 14,
        firstName: "Stan",
        lastName: "Van Tricht",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-20",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      920: {
        id: 920,
        startno: 65,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      16797: {
        id: 16797,
        startno: 235,
        firstName: "Quentin",
        lastName: "Jauregui",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-22",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 74,
        firstName: "Jasha",
        lastName: "S\u00c3\u00bctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      38164: {
        id: 38164,
        startno: 63,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      4317: {
        id: 4317,
        startno: 127,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 42,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      10942: {
        id: 10942,
        startno: 225,
        firstName: "Dimitri",
        lastName: "Peyskens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-26",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      37379: {
        id: 37379,
        startno: 125,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 151,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      729: {
        id: 729,
        startno: 2,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      27334: {
        id: 27334,
        startno: 226,
        firstName: "Remy",
        lastName: "Mertz",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-17",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      53723: {
        id: 53723,
        startno: 45,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 207,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 111,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      75998: {
        id: 75998,
        startno: 153,
        firstName: "Finn",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2001-12-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      602: {
        id: 602,
        startno: 131,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      264: {
        id: 264,
        startno: 171,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      169: {
        id: 169,
        startno: 237,
        firstName: "Jonathan",
        lastName: "Hivert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-03-23",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      67803: {
        id: 67803,
        startno: 245,
        firstName: "Luca",
        lastName: "Rastelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-12-29",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      64479: {
        id: 64479,
        startno: 107,
        firstName: "Abner",
        lastName: "Gonz\u00c3\u00a1lez",
        nationCode: "PUR",
        nationName: "Puerto Rico",
        birthDate: "2000-10-09",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      45356: {
        id: 45356,
        startno: 215,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      38923: {
        id: 38923,
        startno: 194,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-01",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 43,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 122,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 121,
        firstName: "Stefan",
        lastName: "K\u00c3\u00bcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      65447: {
        id: 65447,
        startno: 182,
        firstName: "Tobias",
        lastName: "Bayer",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1999-11-17",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 185,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      3095: {
        id: 3095,
        startno: 137,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 143,
        firstName: "Beno\u00c3\u00aet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      47830: {
        id: 47830,
        startno: 17,
        firstName: "Jannik",
        lastName: "Steimle",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-04-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      2324: {
        id: 2324,
        startno: 1,
        firstName: "Tom",
        lastName: "Dumoulin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      6228: {
        id: 6228,
        startno: 47,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      6496: {
        id: 6496,
        startno: 192,
        firstName: "Winner",
        lastName: "Anacona",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1988-08-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      12481: {
        id: 12481,
        startno: 15,
        firstName: "Florian",
        lastName: "S\u00c3\u00a9n\u00c3\u00a9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      20466: {
        id: 20466,
        startno: 244,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      27240: {
        id: 27240,
        startno: 164,
        firstName: "Jan",
        lastName: "Maas",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-19",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 75,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 11,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      43007: {
        id: 43007,
        startno: 85,
        firstName: "Benjamin",
        lastName: "Thomas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-09-12",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      3147: {
        id: 3147,
        startno: 134,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      65580: {
        id: 65580,
        startno: 72,
        firstName: "Filip",
        lastName: "Maciejuk",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1999-09-03",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      16583: {
        id: 16583,
        startno: 7,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 105,
        firstName: "Iv\u00c3\u00a1n",
        lastName: "Garc\u00c3\u00ada Cortina",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      2313: {
        id: 2313,
        startno: 24,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 115,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 73,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 55,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      16789: {
        id: 16789,
        startno: 202,
        firstName: "Dries",
        lastName: "van Gestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-30",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      29100: {
        id: 29100,
        startno: 234,
        firstName: "Thibault",
        lastName: "Ferasse",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-09-12",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      9349: {
        id: 9349,
        startno: 33,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      64658: {
        id: 64658,
        startno: 67,
        firstName: "Marijn",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-19",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      80271: {
        id: 80271,
        startno: 165,
        firstName: "Alexandre",
        lastName: "Balmer",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2000-05-04",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      19254: {
        id: 19254,
        startno: 193,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      64689: {
        id: 64689,
        startno: 222,
        firstName: "Johan",
        lastName: "Meens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-07-07",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      10871: {
        id: 10871,
        startno: 123,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      20584: {
        id: 20584,
        startno: 61,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      63685: {
        id: 63685,
        startno: 52,
        firstName: "Daan",
        lastName: "Hoole",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      39167: {
        id: 39167,
        startno: 223,
        firstName: "Kenny",
        lastName: "Molly",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-12-24",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 16,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      2326: {
        id: 2326,
        startno: 205,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 186,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      10975: {
        id: 10975,
        startno: 167,
        firstName: "Dion",
        lastName: "Smith",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-03-03",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      4927: {
        id: 4927,
        startno: 86,
        firstName: "Kenneth",
        lastName: "Vanbilsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-06-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      7572: {
        id: 7572,
        startno: 53,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-12-14",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      903: {
        id: 903,
        startno: 236,
        firstName: "Cyril",
        lastName: "Gautier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-09-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      20471: {
        id: 20471,
        startno: 246,
        firstName: "Alessandro",
        lastName: "Tonelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-29",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      37777: {
        id: 37777,
        startno: 155,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      75468: {
        id: 75468,
        startno: 213,
        firstName: "Vito ",
        lastName: "Braet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-11-02",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      6104: {
        id: 6104,
        startno: 191,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      69145: {
        id: 69145,
        startno: 93,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 56,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      46163: {
        id: 46163,
        startno: 152,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      19784: {
        id: 19784,
        startno: 71,
        firstName: "Jack",
        lastName: "Haig",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-09-06",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      63035: {
        id: 63035,
        startno: 44,
        firstName: "S\u00c3\u00a9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      18249: {
        id: 18249,
        startno: 133,
        firstName: "Tom",
        lastName: "Devriendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      349: {
        id: 349,
        startno: 142,
        firstName: "Mika\u00c3\u00abl",
        lastName: "Ch\u00c3\u00a9rel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-03-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      16817: {
        id: 16817,
        startno: 92,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      10802: {
        id: 10802,
        startno: 136,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      102733: {
        id: 102733,
        startno: 25,
        firstName: "Magnus",
        lastName: "Sheffield",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-04-19",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      70377: {
        id: 70377,
        startno: 46,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      103120: {
        id: 103120,
        startno: 101,
        firstName: "Vinicius",
        lastName: "Rangel",
        nationCode: "BRA",
        nationName: "Brazil",
        birthDate: "2001-05-26",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 4,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      33402: {
        id: 33402,
        startno: 243,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 181,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 146,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      16615: {
        id: 16615,
        startno: 176,
        firstName: "Mads",
        lastName: "W\u00c3\u00bcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      41368: {
        id: 41368,
        startno: 84,
        firstName: "Alexandre",
        lastName: "Delettre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-10-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 113,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 32,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 3,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      114659: {
        id: 114659,
        startno: 156,
        firstName: "Juan",
        lastName: "Ayuso",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-09-16",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 77,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      20511: {
        id: 20511,
        startno: 117,
        firstName: "Valerio",
        lastName: "Conti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 162,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      20945: {
        id: 20945,
        startno: 57,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      27297: {
        id: 27297,
        startno: 231,
        firstName: "Franck",
        lastName: "Bonnamour",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-06-20",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      37756: {
        id: 37756,
        startno: 103,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      38301: {
        id: 38301,
        startno: 224,
        firstName: "Mathijs",
        lastName: "Paasschens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-18",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      2294: {
        id: 2294,
        startno: 177,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      37439: {
        id: 37439,
        startno: 34,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      128416: {
        id: 128416,
        startno: 37,
        firstName: "Cian",
        lastName: "Uijtdebroeks",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2003-02-28",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      45371: {
        id: 45371,
        startno: 217,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      16752: {
        id: 16752,
        startno: 83,
        firstName: "Bryan",
        lastName: "Coquard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      66495: {
        id: 66495,
        startno: 36,
        firstName: "Giovanni",
        lastName: "Aleotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      29: {
        id: 29,
        startno: 201,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      37996: {
        id: 37996,
        startno: 227,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      37868: {
        id: 37868,
        startno: 242,
        firstName: "Johnatan",
        lastName: "Ca\u00c3\u00b1averal",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-11-02",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      964: {
        id: 964,
        startno: 204,
        firstName: "Julien",
        lastName: "Simon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-10-04",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      46003: {
        id: 46003,
        startno: 184,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 97,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      2503: {
        id: 2503,
        startno: 82,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      37219: {
        id: 37219,
        startno: 96,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      66841: {
        id: 66841,
        startno: 233,
        firstName: "Alan",
        lastName: "Boileau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-25",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      80586: {
        id: 80586,
        startno: 51,
        firstName: "Filippo",
        lastName: "Baroncini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-08-26",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37294: {
        id: 37294,
        startno: 87,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      94607: {
        id: 94607,
        startno: 212,
        firstName: "Jenno",
        lastName: "Berckmoes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-02-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      16506: {
        id: 16506,
        startno: 35,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1993-09-15",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      71217: {
        id: 71217,
        startno: 26,
        firstName: "Ben",
        lastName: "Turner",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      8813: {
        id: 8813,
        startno: 54,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1992-10-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      38169: {
        id: 38169,
        startno: 102,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 161,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      28169: {
        id: 28169,
        startno: 187,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16581: {
        id: 16581,
        startno: 132,
        firstName: "Aim\u00c3\u00a9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      45620: {
        id: 45620,
        startno: 154,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-10-10",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 91,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 27,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      14123: {
        id: 14123,
        startno: 147,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      174: {
        id: 174,
        startno: 41,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16587: {
        id: 16587,
        startno: 116,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      17203: {
        id: 17203,
        startno: 126,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      2312: {
        id: 2312,
        startno: 241,
        firstName: "Enrico",
        lastName: "Battaglin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-17",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      22133: {
        id: 22133,
        startno: 6,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37427: {
        id: 37427,
        startno: 144,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 62,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      22268: {
        id: 22268,
        startno: 141,
        firstName: "Lilian",
        lastName: "Calmejane",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-06",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      48461: {
        id: 48461,
        startno: 112,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      7771: {
        id: 7771,
        startno: 195,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      1987: {
        id: 1987,
        startno: 135,
        firstName: "Baptiste",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-09-28",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      27307: {
        id: 27307,
        startno: 106,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 197,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      26504: {
        id: 26504,
        startno: 13,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      706: {
        id: 706,
        startno: 173,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      14780: {
        id: 14780,
        startno: 216,
        firstName: "Kenneth",
        lastName: "Van Rooy",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-08",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 196,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 64,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 31,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 124,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 183,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      27333: {
        id: 27333,
        startno: 23,
        firstName: "Laurens",
        lastName: "De Plus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-09-04",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Amstel Gold Race"
  const raceID = 9

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
