import React, { useState, useEffect } from "react"
import "../style/style.css"
import { useTransition, animated } from "react-spring"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Header from "./header"
import useKeyPress from "./useKeyPress"
import Flag from "react-flagpack"
import { ErrorBoundary } from "react-error-boundary"
import { countries } from "./countries"
import yellowJersey from "../images/yellow-jersey.png"
import rightIcon from "../images/right-icon-outline.png"
import leftIcon from "../images/left-icon-outline.png"
import ordinalConvert from "./ordinalConvert"
import { useSwipeable } from "react-swipeable"
import { useFetch } from "./useFetch"
import teamJerseys from "../constants/teamJerseys"

function RacePageOneDay2022({ title, startlistLocal, race, raceID }) {
  const [startlist, setStartlist] = useState(startlistLocal)
  // const [startlistFetch, setStartListFetch] = useFetch(raceID)

  const [currentNumber, setCurrentNumber] = useState("1")
  const [datetimeLastPress, setDatetimeLastPress] = useState(Date.now())
  const handleNumPad = ev => {
    const addedNumber = ev.target.value.toString()
    const timeDifference = Date.now() - datetimeLastPress

    if (currentNumber.length < 3 && timeDifference < 4000) {
      setCurrentNumber(currentNumber.concat(addedNumber))
      setDatetimeLastPress(Date.now())
    } else {
      setCurrentNumber(addedNumber)
      setDatetimeLastPress(Date.now())
    }
  }

  const handleRightTap = () => {
    const previousNumber = currentNumber
    // Count number of riders and highest start number that exists, then go back to 1 at that point, and reverse
    if (currentNumber < 999 && currentNumber) {
      setCurrentNumber((parseInt(previousNumber) + 1).toString()) // Correct for the fact that riders may be limited to 8 per team, i.e. 9 & 19 don't exist
    } else if (currentNumber == 999) {
      setCurrentNumber("1")
    }
  }
  const handleLeftTap = () => {
    const previousNumber = currentNumber
    if (parseInt(currentNumber) > 1 && currentNumber) {
      setCurrentNumber((parseInt(previousNumber) - 1).toString()) // Correct for the fact that riders may be limited to 8 per team, i.e. 9 & 19 don't exist
    }
  }

  const handleBackspace = () => {
    if (currentNumber.length > 0) {
      setCurrentNumber(currentNumber.slice(0, -1))
      setDatetimeLastPress(Date.now())
    }
  }

  // Handle keyboard events
  const lastKeyPressed = useKeyPress()

  useEffect(() => {
    const addedNumber = lastKeyPressed
    const acceptableKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
    ]
    if (
      addedNumber != null &&
      addedNumber != "Backspace" &&
      acceptableKeys.includes(addedNumber)
    ) {
      const timeDifference = Date.now() - datetimeLastPress

      if (currentNumber && currentNumber.length < 3 && timeDifference < 4000) {
        setCurrentNumber(currentNumber.concat(addedNumber))
        setDatetimeLastPress(Date.now())
      } else {
        setCurrentNumber(addedNumber)
        setDatetimeLastPress(Date.now())
      }
    } else if (addedNumber == "Backspace") {
      handleBackspace()
    }
  }, [lastKeyPressed])

  // Toggle between startlist and numpad view
  const [viewMode, setViewMode] = useState("numPad")

  const transition = useTransition(currentNumber, {
    from: { opacity: 0, filter: "blur(1rem)" },
    enter: { opacity: 1, filter: "blur(0rem)" },
    config: { tension: 150, clamp: true },
  })

  // Detect swipes
  const handlers = useSwipeable({
    onSwipedLeft: () => handleRightTap(),
    onSwipedRight: () => handleLeftTap(),
    preventDefaultTouchmoveEvent: true,
  })

  return (
    <div className="App">
      <div className="container">
        <Header title={title} race={race} />
        <div className="toggleContainer">
          <div
            className={`toggleItem ${
              viewMode === "numPad" ? "activeItem" : ""
            }`}
            onClick={() => {
              setViewMode("numPad")
            }}
          >
            <span>Track by Number</span>
          </div>
          <div
            className={`toggleItem ${
              viewMode === "startList" ? "activeItem" : ""
            }`}
            onClick={() => {
              setViewMode("startList")
            }}
          >
            <span>Startlist</span>
          </div>
        </div>
        <div className="currentRider" {...handlers}>
          {currentNumber && (
            <>
              <div
                className="pressLeft"
                onClick={() => {
                  handleLeftTap()
                }}
              >
                {/* 👈 */}
                <img className="leftRightIcon" src={leftIcon} />
              </div>
            </>
          )}
          {Object.values(startlist.riders).find(findRider => {
            return findRider.startno.toString() === currentNumber
          }) && (
            <>
              {transition((style, item) => {
                const rider = Object.values(startlist.riders).find(
                  findRider => {
                    return findRider.startno.toString() == currentNumber
                  }
                )
                const stillInTheRace = rider.stillInTheRace
                  ? rider.stillInTheRace
                  : "Y"

                let showTeamName = false
                const nr = rider.startno.toString()
                if (nr.includes("1") && nr.length == 1) {
                  showTeamName = true
                } else if (
                  nr.includes("1") &&
                  nr.charAt(0) != "1" &&
                  nr.charAt(1) == "1" &&
                  nr.length == 2
                ) {
                  showTeamName = true
                } else if (
                  nr.includes("1") &&
                  nr.charAt(0) == "1" &&
                  nr.charAt(1) == "1" &&
                  nr.length == 2
                ) {
                  showTeamName = true
                } else if (
                  nr.includes("1") &&
                  nr.charAt(0) == "1" &&
                  nr.charAt(2) == "1"
                ) {
                  showTeamName = true
                } else if (
                  nr.includes("1") &&
                  nr.charAt(0) == "2" &&
                  nr.charAt(2) == "1"
                ) {
                  showTeamName = true
                }

                let nationCode = ""
                if (rider.nationCode === "GBR") {
                  nationCode = "GB-UKM"
                } else if (rider.nationCode === "GER") {
                  nationCode = "DEU"
                } else if (rider.nationCode === "NED") {
                  nationCode = "NLD"
                } else if (rider.nationCode === "DEN") {
                  nationCode = "DNK"
                } else if (rider.nationCode === "RSA") {
                  nationCode = "ZAF"
                } else if (rider.nationCode === "POR") {
                  nationCode = "PRT"
                } else if (rider.nationCode === "ROM") {
                  nationCode = "ROU"
                } else if (rider.nationCode === "SBA") {
                  nationCode = "SRB"
                } else if (rider.nationCode === "SUI") {
                  nationCode = "CHE"
                } else if (rider.nationCode === "LAT") {
                  nationCode = "LVA"
                } else if (rider.nationCode === "CRO") {
                  nationCode = "HRV"
                } else if (rider.nationCode === "SLO") {
                  nationCode = "SVN"
                } else {
                  nationCode = rider.nationCode
                }

                // Team Jersey
                let showJersey = teamJerseys.includes(rider.teamId)

                return (
                  <animated.div style={style} className="currentRiderContent">
                    <h2
                      style={{
                        opacity: `${stillInTheRace === "N" ? 0.5 : 1}`,
                        color: `${
                          stillInTheRace === "N" ? "black" : "transparent"
                        }`,
                      }}
                    >
                      <span className="firstName">{rider.firstName} </span>
                      <br />
                      <span className="lastName">
                        {rider.lastName}{" "}
                        <ErrorBoundary
                          fallbackRender={({ error, resetErrorBoundary }) => (
                            <span className="flagCode">{rider.nationCode}</span>
                          )}
                        >
                          {countries.some(
                            country =>
                              country.code ===
                              (nationCode === "GB-UKM" ? "GBR" : nationCode)
                          ) && (
                            <Flag
                              size="L"
                              hasBorder={false}
                              className="flagIcon"
                              code={nationCode}
                            />
                          )}
                        </ErrorBoundary>
                      </span>
                    </h2>
                    <h3 className="gcContainer">
                      <div className="gcRank">#{currentNumber}</div>
                    </h3>
                    <h3 className="currentRiderTeam">
                      {rider.teamName}{" "}
                      {showJersey ? (
                        <img
                          src={`/teamJerseys/${rider.teamId}.png`}
                          className="teamJersey"
                        />
                      ) : null}
                    </h3>

                    {stillInTheRace === "Y" && (
                      <OutboundLink
                        className="riderProfileLink"
                        href={`https://firstcycling.com/rider.php?r=${rider.id}`}
                      >
                        Check on FirstCycling
                        {/* Check profile */}
                      </OutboundLink>
                    )}
                    {stillInTheRace === "N" && (
                      <div className="abandon">Abandon</div>
                    )}
                  </animated.div>
                )
              })}
            </>
          )}
          {!Object.values(startlist.riders).find(findRider => {
            return findRider.startno == currentNumber
          }) && (
            <div className="currentRiderContent">
              <img alt="riderIcon" src="/rider.png" />
            </div>
          )}
          {currentNumber && (
            <>
              <div
                className="pressRight"
                onClick={() => {
                  handleRightTap()
                }}
              >
                <img className="leftRightIcon" src={rightIcon} />
                {/* 👉 */}
                {/* <div id="triangle-right"></div> */}
              </div>
            </>
          )}
        </div>

        {viewMode === "numPad" && (
          <>
            <div
              className="currentNumber"
              style={{
                backgroundColor: `${
                  Object.values(startlist.riders).find(findRider => {
                    return findRider.startno == currentNumber
                  })
                    ? "#cff1c5"
                    : "#c5d4f1"
                }`,
              }}
            >
              {(currentNumber && `#${currentNumber}`) ||
                `Enter a rider's number`}
              <span
                style={{ display: `${currentNumber ? "initial" : "none"}` }}
                className="clearNumberButton"
                onClick={() => setCurrentNumber("")}
              >
                clear
              </span>
            </div>

            <div className="numPad">
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="1"
              >
                1
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="2"
              >
                2
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="3"
              >
                3
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="4"
              >
                4
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="5"
              >
                5
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="6"
              >
                6
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="7"
              >
                7
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="8"
              >
                8
              </button>
              <button
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="9"
              >
                9
              </button>
              <button
                style={{ gridColumn: `1 / span 2` }}
                onClick={ev => {
                  handleNumPad(ev)
                }}
                value="0"
              >
                0
              </button>
              <button
                className="backspace"
                onClick={() => {
                  handleBackspace()
                }}
              >
                <img
                  alt="backspaceIcon"
                  src="/backspace.png"
                />
              </button>
            </div>
          </>
        )}
        {viewMode === "startList" && (
          <div className="startListContainer">
            <div className="startList">
              {Object.values(startlist.riders)
                .sort((a, b) => (a.startno > b.startno ? 1 : -1))
                .map((riderObj, index) => {
                  // Show only the team name before each first rider of a team
                  // Change to IF last char is 1 -> show teamname
                  const rider = riderObj
                  const stillInTheRace = rider.stillInTheRace
                    ? rider.stillInTheRace
                    : "Y"
                  let showTeamName = false
                  const nr = rider.startno.toString()
                  if (nr.includes("1") && nr.length == 1) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(0) != "1" &&
                    nr.charAt(1) == "1" &&
                    nr.length == 2
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(0) == "1" &&
                    nr.charAt(1) == "1" &&
                    nr.length == 2
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(0) == "1" &&
                    nr.charAt(2) == "1"
                  ) {
                    showTeamName = true
                  } else if (
                    nr.includes("1") &&
                    nr.charAt(0) == "2" &&
                    nr.charAt(2) == "1"
                  ) {
                    showTeamName = true
                  }

                  // Show correct flag again
                  let nationCode = ""
                  if (rider.nationCode === "GBR") {
                    nationCode = "GB-UKM"
                  } else if (rider.nationCode === "GER") {
                    nationCode = "DEU"
                  } else if (rider.nationCode === "NED") {
                    nationCode = "NLD"
                  } else if (rider.nationCode === "DEN") {
                    nationCode = "DNK"
                  } else if (rider.nationCode === "RSA") {
                    nationCode = "ZAF"
                  } else if (rider.nationCode === "POR") {
                    nationCode = "PRT"
                  } else if (rider.nationCode === "ROM") {
                    nationCode = "ROU"
                  } else if (rider.nationCode === "SBA") {
                    nationCode = "SRB"
                  } else if (rider.nationCode === "SUI") {
                    nationCode = "CHE"
                  } else if (rider.nationCode === "LAT") {
                    nationCode = "LVA"
                  } else if (rider.nationCode === "CRO") {
                    nationCode = "HRV"
                  } else if (rider.nationCode === "SLO") {
                    nationCode = "SVN"
                  } else {
                    nationCode = rider.nationCode
                  }

                  // Team Jersey
                  let showJersey = teamJerseys.includes(rider.teamId)

                  return (
                    <>
                      {showTeamName && (
                        <div className="listTeamName">
                          {rider.teamName}{" "}
                          {showJersey ? (
                            <img src={`/teamJerseys/${rider.teamId}.png`} />
                          ) : null}
                        </div>
                      )}
                      <div
                        key={index}
                        className={`listRider ${
                          currentNumber == nr ? "listActiveRider" : ""
                        }`}
                        onClick={() => {
                          setCurrentNumber(nr)
                        }}
                        style={{
                          opacity: `${stillInTheRace === "N" ? 0.5 : 1}`,
                        }}
                      >
                        <div>
                          <span className="listRiderNumber">{nr}</span>
                          <span
                            style={{
                              textDecoration: `${
                                stillInTheRace === "N" ? "line-through" : ""
                              }`,
                            }}
                          >
                            {rider.firstName} {rider.lastName}
                          </span>
                        </div>
                        <ErrorBoundary
                          fallbackRender={({ error, resetErrorBoundary }) => (
                            <span className="flagCode">{rider.nationCode}</span>
                          )}
                        >
                          {countries.some(
                            country =>
                              country.code ===
                              (nationCode === "GB-UKM" ? "GBR" : nationCode)
                          ) && (
                            <Flag
                              size="M"
                              hasBorder={false}
                              className="flagIcon"
                              code={nationCode}
                              style={{ marginLeft: `auto` }}
                            />
                          )}
                        </ErrorBoundary>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RacePageOneDay2022
