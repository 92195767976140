// This array contains all teamIds for which a jersey exists
const teamJerseys = [
  21801,
  21803,
  21804,
  21809,
  21812,
  21814,
  21818,
  21820,
  21823,
  21827,
  21830,
  21833,
  21837,
  21839,
  21843,
  21845,
  21850,
  21856,
  21865,
  21868,
  22016,
  22245,
  22290,
  22480,
  22591,
  22925,
  23137,
  23398,
  23399,
  23455,
]

export default teamJerseys
